var ACE = ACE || {};

(function ($) {
	ACE.RegisterPlugin = function (name, object) {
        $.fn[name] = function (options) {
            var initialArgs = arguments;
			return this.each(function (i, e) {
				var instance = $.data(this, name);
				if (instance) {
					// already initialized try to call method
					var isMethodCall = typeof options === "string";
					if (isMethodCall) {
                        var args = Array.prototype.slice.call(initialArgs, 1);
						if ($.isFunction(instance[options])) {
							instance[options].apply(instance, args);
						}
					}
				} else {
					$.data(this, name, new object(this, options));
				}
			});
		};
	};

}(jQuery));