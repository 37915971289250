var ACE = ACE || {};
ACE.Btn = ACE.Btn || {};

ACE.Btn.ButtonLoading = class ButtonLoading extends ACE.Btn.LoadingWrap {
    constructor(element, options) {
        super(element, options);

        this._bindClickEvent();
    }

    _generateOptions(options) {
        return super._generateOptions($.extend(true, {}, ButtonLoading._getDefaultOptions(), options));
    }

    _trigger(type, event, data) {
        var prop, orig;
        var callback = this.options[type];

        data = data || {};
        event = $.Event(event);
        event.type = (type === 'acebuttonloading' ?
            type :
            'acebuttonloading' + type).toLowerCase();

        // The original event may come from any element
        // so we need to reset the target on the new event
        event.target = this.element[0];

        // Copy original event properties over to the new event
        orig = event.originalEvent;
        if (orig) {
            for (prop in orig) {
                if (!(prop in event)) {
                    event[prop] = orig[prop];
                }
            }
        }

        this.element.trigger(event, data);
        return !($.isFunction(callback) &&
            callback.apply(this.element[0], [event].concat(data)) === false ||
            event.isDefaultPrevented());
    }

    _bindClickEvent() {
        var ctl = this;
        this.element.click(function (event, data) {
            if (!ctl.toggled) {
                ctl.toggle(true);
                if (!ctl._trigger('click', event, ctl)) {
                    ctl.toggle(false);
                }
                if (ctl.options.preventDefaultClick) {
                    return false;
                }
            }
            else {
                event.preventDefault();
                return false;
            }
        });
    }

    static _getDefaultOptions() {
        return {
            preventDefaultClick: false
        };
    }
};