var ACE = ACE || {};
ACE.Btn = ACE.Btn || {};

ACE.Btn.LoadingWrap = class LoadingWrap {
    constructor(element, options) {
        this.element = $(element);
        this.options = this._generateOptions(options);
        this.toggled = false;

        //calling this so the loadingWrapper is populated in the DOM when LoadingWrap is called
        this.loadingWrapper;
    }

    _generateOptions(options) {
        return $.extend(true, {}, LoadingWrap._getDefaultOptions(), options);
    }

    toggle(bShowLoading) {
        this.toggled = bShowLoading;
        if (bShowLoading) {
            this.toggleElement.hide();
            this.loadingWrapper.show();
        } else {
            this.toggleElement.show();
            this.loadingWrapper.hide();
        }
    }

    get toggleElement() {
        let populateToggleElement = function (ctl) {
            let toggleElementSelector = ctl.options.loadingControlOptions.toggleElementSelector;

            if ($.isFunction(toggleElementSelector)) {
                return toggleElementSelector.call(ctl);
            }
            if (toggleElementSelector !== null) {
                return ctl.element.find(toggleElementSelector);
            } else {
                return ctl.element;
            }
        };

        if (!(this._toggleElement instanceof jQuery)) {
            this._toggleElement = populateToggleElement(this);
        }

        return this._toggleElement;
    }

    get loadingWrapper() {
        if (!(this._loadingWrapper instanceof jQuery)) {
            var wrapClass = this.options.loadingControlOptions.wrapperClass + (this.options.loadingControlOptions.inheritToggledElementClass ? ' ' + this.toggleElement.prop('class') : '');
            var wrapper = $('<div />', { 'class': wrapClass, 'style': 'display:none;' });
            $('<img />', { 'class': this.options.loadingControlOptions.animationClass, src: this.options.loadingControlOptions.animatedImage })
                .appendTo($('<' + this.options.loadingControlOptions.loadingTextTag + ' />', { html: this.options.loadingControlOptions.loadingText })
                    .appendTo(wrapper));
            this._loadingWrapper = wrapper;
            this._loadingWrapper.insertAfter(this.toggleElement);
        }

        return this._loadingWrapper;
    }

    static _getDefaultOptions() {
        return {
            loadingControlOptions: {
                wrapperClass: 'LoadingWrap',
                animationClass: 'LoadingAnimation',
                animatedImage: '/images/ajax-loader.gif',
                loadingTextTag: 'h3',
                loadingText: 'Please wait... &nbsp;&nbsp;',
                inheritToggledElementClass: false,
                toggleElementSelector: null
            }
        };
    }
};