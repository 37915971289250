var ACE = ACE || {};
ACE.Btn = ACE.Btn || {};

ACE.Btn.AjaxButtonLoading = class AjaxButtonLoading extends ACE.Btn.ButtonLoading {
    constructor(element, options) {
        super(element, options);
    }

    _generateOptions(options) {
        var optionsToPass = $.extend(true, {},
            AjaxButtonLoading._getDefaultAjaxButtonLoadingOptions(),
            options,
            {
                originalClickHandler: options ? options.click : null,
                click: AjaxButtonLoading._buttonClicked
            });

        return super._generateOptions(optionsToPass);
    }

    static _getOptionalAjaxParams(ctrl) {
        var ajaxOptions = {};

        if (ctrl.options.contentType) ajaxOptions.contentType = ctrl.options.contentType;
        if (ctrl.options.dataType) ajaxOptions.dataType = ctrl.options.dataType;
        if (ctrl.options.statusCode) ajaxOptions.statusCode = ctrl.options.statusCode;

        return ajaxOptions;
    }

    static _getDefaultAjaxButtonLoadingOptions() {
        return {
            submitURL: '',
            submitData: null,
            preventDefaultClick: true,
            preventDataSerialization: false,
            submitType: '',
            onSuccess: null,
            onError: null,
            onUnauthorized: null,
            onComplete: null
        };
    }

    static _buttonClicked(event, ctrl) {

        if (!ctrl._trigger('originalClickHandler', null, ctrl)) {
            return false;
        }

        if (!ctrl._triggerBeginBtnClick()) {
            return false;
        }

        var url, strData, antiForgeryToken;
        try {
            url = ctrl._getSubmitURL();
            var submitData = ctrl._getSubmitData() || {};
            if (ctrl.options.preventDataSerialization) {
                strData = submitData;
            } else {
                strData = JSON.stringify(submitData);
            }
            antiForgeryToken = submitData['__RequestVerificationToken'] || '';
        }
        catch (e) {
            console.log(e);
            return false;
        }

        $.ajax($.extend(true, {
            type: ctrl._getOptionResult('submitType'),
            url: url,
            data: strData,
            success: function (data, status, xhr) {
                ctrl.toggle(false);

                if (!ctrl._handleUnathorizedHeader(xhr, status)) return false;

                ctrl._triggerSuccess(data, status, xhr);
            },
            error: function (xhr, status, error) {
                // check if xhr.status is defined in $.ajax.statusCode
                // if true, return false to stop this function
                if (typeof this.statusCode !== 'undefined' && this.statusCode[xhr.status] != 'undefined') {
                    return false;
                }

                ctrl.toggle(false);

                // deal with error
                ctrl._triggerError(xhr, status, error);
            },
            complete: function (xhr, status) {
                ctrl._triggerOnComplete(xhr, status);
            },
            headers: {
                "__RequestVerificationToken": antiForgeryToken
            }
        }, AjaxButtonLoading._getOptionalAjaxParams(ctrl)));

        return true;
    }

    static _isUnauthorizedResult(xhr, status) {
        var header = xhr.getResponseHeader("X-Responded-JSON");
        if (header !== null && JSON.parse(header).status === "401") {
            return true;
        }
        return false;
    }

    _handleUnathorizedHeader(xhr, status) {
        if (AjaxButtonLoading._isUnauthorizedResult(xhr, status)) {
            return this._triggerUnauthorized(xhr, status);
        }
        return true;
    }

    /* [Helper Functions] */
    _triggerFn(type) {
        var ctl = this;
        var callback = ctl.options[type];
        if ($.isFunction(callback)) {
            return callback.apply(ctl, Array.prototype.slice.call(arguments, 1));
        }
        return true;
    }

    _getOptionResult(type) {
        var ctl = this;
        var option = ctl.options[type];
        if ($.isFunction(option)) {
            return option.apply(ctl, Array.prototype.slice.call(arguments, 1));
        }
        return option;
    }

    /* [Retrieval Functions] */
    _getSubmitData() {
        return this._getOptionResult('submitData');
    }
    _getSubmitURL() {
        return this._getOptionResult('submitURL');
    }
    /* [Trigger Functions] */
    _triggerSuccess(response, status, xhr) {
        return this._triggerFn('onSuccess', response, status, xhr);
    }
    _triggerOriginalSuccess(response, status, xhr) {
        return this._triggerFn('originalSuccessHandler', response, status, xhr);
    }
    _triggerOnComplete(xhr, status) {
        return this._triggerFn('onComplete', xhr, status);
    }
    _triggerError(xhr, status, error) {
        return this._triggerFn('onError', xhr, status, error);
    }
    _triggerOriginalError(xhr, status, error) {
        return this._triggerFn('originalErrorHandler', xhr, status, error);
    }
    _triggerUnauthorized(xhr, status) {
        return this._triggerFn('onUnauthorized', xhr, status);
    }
    _triggerBeginBtnClick() {
        return this._triggerFn('onBeginningBtnClicked');
    }
};